<template>
  <div class="report-item mb-4">
    <div class="tagreport-block" :class="showSublist ? 'tagreport-block--open' : ''">
      <div class="d-flex align-center">
        <span class="tag-mp-icon mr-8">mp</span>
        <div class="d-flex">
          <button class="report-open-sublist color-1 fw-600 mr-10" @click="openSublist(multiprofile.id)">
            <span class="tagname mr-12">{{name}}</span>
            <Icon name="arrow-down-white" :class="showSublist ? 'arrow-up-white' : 'arrow-down-white'" class="mr-12" color="#60BCE3" />
          </button>
          <span class="typography-7 w-100">{{profiles.length}} profiles</span>
        </div>
      </div>
      <div class="d-flex">
        <input type="checkbox" name="" class="sidebar-checkbox" :checked="multiprofile.checked">
        <label class="sidebar-checkbox__label" @click="checkAllProfiles(multiprofile)"></label>
      </div>
    </div>
    <div :ref="'sublist-' + multiprofile.id" :id="'sublist-' + multiprofile.id" class="sublist-accordeon">
      <div class="note-block">
        <label for="" class="mb-12 color-miniheader typography-6 fw-600">Note</label>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          class="note-area w-100"
          placeholder="Place for your note"
          v-model="mpNote"
          @blur="saveNote"
        ></textarea>
      </div>
      <ul class="note-block">
        <div class="color-miniheader typography-6 mb-12 fw-600">Profiles info</div>
        <template v-for="profile in profiles" :key="profile.oid">
          <ReportItem :entity="profile"/>
        </template>
      </ul>
      <div class="note-block pt-0" v-if="showScoreFilterBlock">
        <div class="color-miniheader typography-6 fw-600 mb-12">Connections</div>
        <div class="d-flex justify-between">
          <ul class="score-checkboxes">
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" :checked="multiprofile.scoreToShow?.['s-250+']" ref="s-250+">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-250+')"><span>Score 250+</span></label>
            </li>
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" :checked="multiprofile.scoreToShow?.['s-150+']" ref="s-150+">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-150+')"><span>Score 150-249</span></label>
            </li>
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" :checked="multiprofile.scoreToShow?.['s-50+']" ref="s-50+">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-50+')"><span>Score 50-149</span></label>
            </li>
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" :checked="multiprofile.scoreToShow?.['s-1+']" ref="s-1+">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-1+')"><span>Score 1-49</span></label>
            </li>
          </ul>
          <div class="score-desc">
            <div class="fw-600">Connection score</div>
            <p><span class="fw-600">Friend (+50 points) –</span> the profile is in the subjects’s friend list</p>
            <p><span class="fw-600">Tagged with (+30 points) –</span> the person was tagged in a photo with the subject</p>
            <p><span class="fw-600">Liked post/photo (+3 points) –</span> the person liked the user's post or photo</p>
            <p><span class="fw-600">Commented post/photo (+3 points) –</span> the person commented on the  subject's post or photo</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '../app/Icon.vue';
import ReportItem from './ReportItem.vue';

export default {
  name: 'ReportMultiprofile',
  components: {
    Icon,
    ReportItem
  },
  props: ['multiprofile'],
  data () {
    return {
      mpNote: '',
      showSublist: false,
      sortedConnByScore: {
        's-1+': true,
        's-50+': true,
        's-150+': true,
        's-250+': true
      }
    };
  },
  created () {
    if (this.multiprofile.scoreToShow) {
      this.sortedConnByScore = this.multiprofile.scoreToShow;
    } else {
      this.$store.commit('setMultiprofileScore', { mp: this.multiprofile.id, score: this.sortedConnByScore });
    }

    this.getReportItems.forEach(item => {
      if (item.oid === this.multiprofile.id) {
        this.mpNote = item.note || '';
      }
    });
  },
  computed: {
    ...mapGetters(['resultsMap', 'sortedRelationSearches', 'getReportItems']),
    profiles () {
      const profiles = [];
      this.multiprofile && this.multiprofile.entity_ids.forEach(id => {
        for (const key in this.resultsMap) {
          if (Object.prototype.hasOwnProperty.call(this.resultsMap, key) && key === id) {
            profiles.push(this.resultsMap[key]);
          }
        }
      });
      return profiles;
    },
    name () {
      function sortNames (profiles, socialNetwork) {
        for (let i = 0; i < profiles.length; i++) {
          if (profiles[i].typeid === socialNetwork) {
            return profiles[i].fields.name?.value || profiles[i].fields['person.name']?.value;
          }
        }
        return '';
      }

      if (this.profiles && this.profiles.length) {
        let sortedName;
        sortedName = sortNames(this.profiles, 'maltego.facebook.profile');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.profiles, 'maltego.linkedin.profile');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.profiles, 'maltego.affiliation.Twitter');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.profiles, 'maltego.instagram.profile');
        if (sortedName) {
          return sortedName;
        }
      }
      return '';
    },
    showScoreFilterBlock () {
      let res;
      for (let i = 0; i < this.sortedRelationSearches.length; i++) {
        const targets = this.sortedRelationSearches[i].targets;
        for (let j = 0; j < targets.length; j++) {
          res = this.profiles.find(profile => {
            return targets[j] === profile.oid;
          });
          if (res) break;
        }
        if (res) break;
      }
      return res;
    }
  },
  methods: {
    openSublist (oid) {
      const elem = this.$refs['sublist-' + oid];
      if (elem.style.maxHeight) {
        elem.style.maxHeight = null;
        this.showSublist = false;
      } else {
        elem.style.maxHeight = elem.scrollHeight + 'px';
        this.showSublist = true;
      }
    },
    checkConnections (score) {
      const elem = this.$refs[score];
      if (elem.checked) {
        elem.checked = false;
        this.sortedConnByScore[score] = false;
      } else {
        elem.checked = true;
        this.sortedConnByScore[score] = true;
      }
      this.$store.commit('setMultiprofileScore', { mp: this.multiprofile.id, score: this.sortedConnByScore });
    },
    async saveNote () {
      try {
        const obj = {
          entity: this.multiprofile,
          caseId: this.$route.params.id,
          note: this.mpNote
        };
        await this.$store.dispatch('saveItemNote', obj);
      } catch (error) {
        console.error(error);
      }
    },
    checkAllProfiles (mp) {
      mp.checked ? mp.checked = false : mp.checked = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-mp-icon {
  color: #60BCE3;
  border: 2px solid rgba(96, 188, 227, 0.3);
}
</style>
