<template>
  <div class="d-flex justify-between mb-48">
    <div>
      <img class="tagged-profile-card__avatar mb-32" :src="sourceAvatar" @load="avatarLoadHandler('source')" @error="avatarErrorHandler('source')" :class="loadSourceAvatar ? 'd-block' : 'd-none'">
      <img class="tagged-profile-card__avatar mb-32" src="@/assets/icons/profile-card-avatar.svg" :class="loadSourceAvatar ? 'd-none' : 'd-block'">
      <div class="mb-24">
        <h3 class="typography-1">{{sourceName}}</h3>
        <div class="color-light" v-if="location(source)">{{location(source)}}</div>
      </div>
      <template v-if="edge.note">
        <div class="color-miniheader typography-theme mb-12">Note</div>
        <div class="typography-8 mb-24">{{edge.note}}</div>
      </template>
      <div class="color-miniheader typography-theme mb-12">Sources</div>
      <div class="d-flex align-center typography-8 mb-8">
        <span class="icon-wrap">
          <Icon :name="socialIconFromProfile(source)" :data-svg-replace="socialIconFromProfile(source)" class="mr-12" />
        </span>
        <span>{{sourceName}} &nbsp;</span>
      </div>
    </div>
    <div>
      <img class="tagged-profile-card__avatar mb-32" :src="destinationAvatar" @load="avatarLoadHandler('')" @error="avatarErrorHandler('')" :class="loadDestinationAvatar ? 'd-block' : 'd-none'">
      <img class="tagged-profile-card__avatar mb-32" src="@/assets/icons/profile-card-avatar.svg" :class="loadDestinationAvatar ? 'd-none' : 'd-block'">
      <div class="mb-24">
        <h3 class="typography-1">{{destinationName}}</h3>
        <div class="color-light mb-24" v-if="location(destination)">{{location(destination)}}</div>
      </div>
      <template v-if="edge.note">
        <div class="color-miniheader typography-theme mb-12">Note</div>
        <div class="typography-8 mb-24">{{edge.note}}</div>
      </template>
      <div class="color-miniheader typography-theme mb-12">Sources</div>
      <div class="d-flex align-center typography-8 mb-8">
        <span class="icon-wrap">
          <Icon :name="socialIconFromProfile(destination)" :data-svg-replace="socialIconFromProfile(destination)" class="mr-12" />
        </span>
        <span>{{destinationName}} &nbsp;</span>
      </div>
    </div>
  </div>
  <ul class="relation-info mb-48">
    <li>
      <div class="typography-3 color-miniheader mb-8">{{score}}</div>
      <div class="typography-7">Connections score</div>
    </li>
    <li v-if="+destination.fields.mentioned_count.value">
      <div class="typography-3 color-miniheader mb-8">{{destination.fields.mentioned_count.value}}</div>
      <div class="typography-7">Mentioned</div>
    </li>
    <li v-if="+destination.fields.commented_count.value">
      <div class="typography-3 color-miniheader mb-8">{{destination.fields.commented_count.value}}</div>
      <div class="typography-7">Comments</div>
    </li>
    <li v-if="+destination.fields.liked_count.value">
      <div class="typography-3 color-miniheader mb-8">{{destination.fields.liked_count.value}}</div>
      <div class="typography-7">Likes</div>
    </li>
    <li v-if="+destination.fields.reacted_count.value">
      <div class="typography-3 color-miniheader mb-8">{{destination.fields.reacted_count.value}}</div>
      <div class="typography-7">Reacted</div>
    </li>
    <li v-if="+destination.fields.repost_count.value">
      <div class="typography-3 color-miniheader mb-8">{{destination.fields.repost_count.value}}</div>
      <div class="typography-7">Reposted</div>
    </li>
    <li v-if="+destination.fields.tagged_count.value">
      <div class="typography-3 color-miniheader mb-8">{{destination.fields.tagged_count.value}}</div>
      <div class="typography-7">Tags</div>
    </li>
  </ul>
  <div v-if="posts?.length">
    <div class="typography-3 color-miniheader mb-24">Events</div>
    <div class="connection-posts">
      <ConnectionPost v-for="post in posts" :key="post.oid" :post="post" :connection="destination" :source="source" />
    </div>
  </div>
</template>

<script>
import Icon from '../app/Icon';
import ConnectionPost from '../ConnectionPost';
import { mapGetters } from 'vuex';
import { getEntityName, getEntityAvatar, getLocation } from '../../utils/helpers';

export default {
  name: 'ReportConnectionPreview',
  components: {
    Icon, ConnectionPost
  },
  props: ['edge'],
  data () {
    return {
      parentNode: null,
      childNode: null,
      connectionPosts: [],
      error: false,
      destinationAvatar: '',
      loadSourceAvatar: false,
      loadDestinationAvatar: false
    };
  },
  async mounted () {
    if (this.destination) {
      this.childNode = this.destination;
    }
    if (this.source) {
      this.parentNode = this.source;
    }
    const postsLoaded = !!this.posts.length;
    if (!this.source || !this.destination || (this.postExists && !postsLoaded)) {
      const payload = {
        method: 'POST',
        url: '/api/user/searches/relation',
        body: {
          id: this.edge.oid,
          search_id: this.edge.rid,
          levels: [3, 4]
        }
      };
      if (this.edge.parent_id) {
        payload.body.parent_id = this.edge.parent_id;
      }
      const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
      if (response.status === 200) {
        const results = await this.$store.dispatch('convertEntityFieldsToMap', response.data);
        this.connectionPosts = results.filter(ent => ent.level === 4).sort(this.comparePostDates);
        if (!this.source) {
          this.parentNode = results.find(ent => ent.level === 1);
          this.parentNode.checked = false;
          this.$store.commit('addProfileToResultsMap', this.parentNode);
        }
        if (!this.destination) {
          this.childNode = results.find(ent => ent.level === 3);
          this.childNode.checked = false;
          this.$store.commit('addProfileToResultsMap', this.childNode);
        }
        this.childNode.posts = this.connectionPosts;
      } else {
        this.error = true;
      }
    }
    this.$store.commit('setLoadUrl', false);
    this.$store.commit('setSearching', false);
    this.destinationAvatar = getEntityAvatar(this.destination);
  },
  computed: {
    ...mapGetters(['connectionEntitiesMap']),
    resultsMap () {
      return this.$store.getters.resultsMap;
    },
    source () {
      if (this.parentNode) {
        return this.parentNode;
      }
      let ent = this.resultsMap[this.edge.parent_id];
      if (!ent) {
        ent = this.connectionEntitiesMap[this.edge.parent_id];
      }
      return ent;
    },
    destination () {
      if (this.childNode) {
        return this.childNode;
      }
      if (!this.edge.child_id) {
        for (const oid of Object.keys(this.resultsMap)) {
          const ent = this.resultsMap[oid];
          if (ent.parent_id === this.edge.oid) {
            return ent;
          }
        }
        for (const oid of Object.keys(this.connectionEntitiesMap)) {
          const ent = this.connectionEntitiesMap[oid];
          if (ent.parent_id === this.edge.oid) {
            return ent;
          }
        }
      }
      let ent = this.resultsMap[this.edge.child_id];
      if (!ent) {
        ent = this.connectionEntitiesMap[this.edge.child_id];
      }
      return ent;
    },
    sourceName () {
      return getEntityName(this.source);
    },
    destinationName () {
      return getEntityName(this.destination);
    },
    sourceAvatar () {
      return getEntityAvatar(this.source);
    },
    score () {
      try {
        return this.destination.fields.score.value;
      } catch (e) {
        return 0;
      }
    },
    mutualFriends () {
      try {
        return this.destination.fields.mutual_friends.value;
      } catch (e) {
        return 0;
      }
    },
    connectionTypes () {
      try {
        return this.destination.fields.connection_types.value.split(',');
      } catch (e) {
        return [];
      }
    },
    postExists () {
      const condition1 = this.destination?.post_exists || this.destination?.fields?.post_exists?.value;
      const condition2 = this.childNode?.post_exists || this.childNode?.fields?.post_exists?.value;
      return condition1 || condition2;
    },
    posts () {
      if (this.connectionPosts.length) {
        return this.slicePosts(this.connectionPosts);
      }
      if (this.childNode?.posts) {
        if (Array.isArray(this.childNode.posts)) {
          return this.slicePosts(this.childNode.posts);
        } else {
          const posts = [];
          Object.keys(this.childNode.posts).forEach(key => posts.push(this.childNode.posts[key]));
          return this.slicePosts(posts);
        }
      }
      if (this.destination?.posts) {
        const posts = [];
        Object.keys(this.destination.posts).forEach(key => posts.push(this.destination.posts[key]));
        return this.slicePosts(posts);
      }
      return [];
    }
  },
  methods: {
    comparePostDates (post1, post2) {
      return post2.fields.date.value - post1.fields.date.value;
    },
    slicePosts (posts) {
      posts.sort(this.comparePostDates);
      if (this.edge.countPostsToShow && typeof (this.edge.countPostsToShow) === 'number' && this.$route.name === 'Report') {
        return posts.slice(0, this.edge.countPostsToShow);
      } else {
        return posts;
      }
    },
    profileId (profile) {
      return `<a href="${profile.fields.surl?.value || profile.fields.url?.value}" target="_blank">(id: ${profile.fields.id.value})</a>`;
    },
    location (profile) {
      return getLocation(profile);
    },
    avatarLoadHandler (type) {
      if (type === 'source') {
        this.loadSourceAvatar = true;
      } else {
        this.loadDestinationAvatar = true;
      }
    },
    avatarErrorHandler (type) {
      if (type === 'source') {
        this.loadSourceAvatar = false;
      } else {
        this.loadDestinationAvatar = false;
      }
    },
    socialIconFromProfile (profile) {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (profile.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    }
  }
};
</script>
