<template>
  <div class="loading-block" v-if="loading">
    <div class="mb-12">
      <img src="@/assets/icons/searching.gif" alt="" class="h-78">
    </div>
    <span class="typography-3 mb-6" >Loading...</span>
  </div>
  <div v-if="!showPreview">
    <div class="d-flex align-center justify-center w-100 h-48">
      <h1 class="typography-1 color-1 mr-16 fw-600" v-if="!inputH1">{{title || 'My report'}}</h1>
      <input
        id="input-h1"
        type="text"
        class="case-name-input typography-3 mr-16 w-100"
        v-model.trim="title"
        @keydown.enter="updateReportHeaders('h1')"
        maxlength="25"
        v-else
      >
      <button id="show-input-h1" @click="showInputH1" v-if="!inputH1">
        <Icon name="edit" class="icon-hover" :color="'#60BCE3'" />
      </button>
      <template v-else>
        <button id="close-input-h1" @click="closeInputH1" class="mr-12">
          <Icon name="cancel" />
        </button>
        <button id="update-report-header-1" @click="updateReportHeaders('h1')">
          <Icon name="ok" />
        </button>
      </template>
    </div>
    <div class="d-flex align-center justify-center w-100 h-48 mb-24">
      <h2 class="typography-5 fw-400 color-2 mr-16 fw-600" v-if="!inputH2">{{subtitle || 'Open Source Investigation'}}</h2>
      <input
        id="input-h2"
        type="text"
        class="case-name-input typography-3 mr-16 w-100"
        v-model.trim="subtitle"
        @keydown.enter="updateReportHeaders('h2')"
        maxlength="25"
        v-else
      >
      <button id="show-input-h2" @click="showInputH2" v-if="!inputH2">
        <Icon name="edit" class="icon-hover" :color="'#60BCE3'" />
      </button>
      <template v-else>
        <button id="close-input-h2" @click="closeInputH2" class="mr-12">
          <Icon name="cancel" />
        </button>
        <button id="update-report-header-2" @click="updateReportHeaders('h2')">
          <Icon name="ok" />
        </button>
      </template>
    </div>
    <div class="d-flex flex-column mb-24">
      <span class="fs-16 custom-color fw-600 mb-12 let-space-04">Text</span>
      <span class="typography-caption color-2 mb-4 let-space-08">Introduction</span>
      <textarea
        name=""
        id=""
        cols="30"
        rows="7"
        :placeholder="description || 'Type your text then press Enter'"
        class="report-textarea color-2"
        v-model="description"
        @blur="updateReportHeaders"
        @keypress.enter="updateReportHeaders"
        :disabled="!notEmptyTags.length"
      ></textarea>
    </div>
    <div>
      <span class="fs-16 custom-color fw-600 mb-12 let-space-04">Items</span>
      <div class="empty-report__container d-flex mt-12" v-if="!notEmptyTags.length && !multiprofiles.length">
        <div class="empty-report__image"> <Icon name="document" /> </div>
        <div class="empty-report__content">
          <h3 class="typography-5 color-theme">Tag profiles or connections you want to be in the report!</h3>
          <p class="typography-8">
            You have not saved anything to your tags. Choose profiles or connections and add some tag to them.
          </p>
          <button id="report-back" class="opacity-btn mt-24" @click="$router.back()">Back</button>
        </div>
      </div>
      <div class="mt-16 mb-24" v-if="showElementsOfReport">
        <template v-for="(tag, index) in notEmptyTags" :key="tag.oid || index">
          <ReportTag :tag="tag" />
        </template>
        <template v-for="mp in multiprofiles" :key="mp.id">
          <ReportMultiprofile :multiprofile="mp" />
        </template>
      </div>
      <div class="d-flex justify-center">
        <button
          id="report-preview"
          class="primary-btn"
          @click="openPreview"
          :disabled="checkItemsToPreview === 0"
          v-if="notEmptyTags.length || multiprofiles.length"
        >
          Preview
        </button>
      </div>
    </div>
  </div>
  <div class="report-preview" v-else-if="!loading">
    <div class="report-preview_wrap" id="htmltopdf" ref="getHtml">
      <div class="d-flex flex-column align-center justify-center w-100 mb-48">
        <h1 class="typography-1 color-1">{{title || 'Report'}}</h1>
        <h2 class="typography-5 fw-400 color-2 mb-16" v-if="subtitle">{{subtitle}}</h2>
        <p class="typography-8 w-100" v-if="description">{{description}}</p>
      </div>
      <div class="pb-48">
        <div v-for="tag in tagsInPreview" :key="tag.oid" class="mb-48" style="page-break-inside:avoid">
          <div class="d-flex flex-column mb-24">
            <span class="typography-3 color-1 mb-6">{{tag.name}}</span>
            <span class="typography-8">{{tag.note}}</span>
          </div>
          <div v-for="item in getItemFromTag(tag)" :key="item.oid" class="mb-48">
            <ReportProfilePreview :profiles="[item]" :multiprofile="null" />
          </div>
          <div class="connections html2pdf__page-break mb-48" v-for="connection in getConnectionsFromTag(tag)" :key="connection.oid">
            <ReportConnectionPreview :edge="connection" />
          </div>
        </div>
        <div v-for="multiprofile in multiprofilesInPreview" :key="multiprofile.id" class="mb-48">
          <ReportProfilePreview :profiles="getProfiles(multiprofile)" :multiprofile="multiprofile" />
        </div>
      </div>
    </div>
  </div>
  <ReportPopup :xls="profilesForXLS.length" @closePreview="closePreview" @createXLS="createXLS" @createHtml="sendHtml" v-if="showPreview && !loading" />
</template>

<script>
import Icon from '../app/Icon.vue';
import ReportTag from './ReportTag.vue';
import ReportMultiprofile from './ReportMultiprofile.vue';
import ReportPopup from './ReportPopup';
import ReportProfilePreview from './ReportProfilePreview';
import ReportConnectionPreview from './ReportConnectionPreview';
import { mapGetters } from 'vuex';
import { getEntityAvatar, getEntityName, getEntityUrl, clearingString } from '../../utils/helpers';
import getCssData from 'get-css-data';
import XLSX from 'xlsx';
import profilePreviewMixin from '@/mixins/profilePreviewMixin';

export default {
  name: 'ReportComponent',
  components: {
    Icon,
    ReportTag,
    ReportPopup,
    ReportMultiprofile,
    ReportProfilePreview,
    ReportConnectionPreview
  },
  mixins: [profilePreviewMixin],
  data () {
    return {
      inputH1: false,
      inputH2: false,
      title: '',
      subtitle: '',
      description: '',
      showPreview: false,
      tagsInPreview: [],
      checkedResultsInTags: [],
      checkedConnectionsInTags: [],
      loading: false,
      showElementsOfReport: false,
      multiprofilesInPreview: []
    };
  },
  async mounted () {
    this.title = this.getTitle;
    this.subtitle = this.getSubtitle;
    this.description = this.getDescription;

    // load connections of profiles
    this.$store.commit('clearConnections', false);
    this.$store.commit('clearEdges', false);
    const set = new Set();
    this.results.forEach(profile => {
      let res;
      if (this.multiprofiles?.length) {
        res = this.multiprofiles.find(mp => {
          return mp.entity_ids.find(id => {
            return id === profile.oid;
          });
        });
        if (res) {
          set.add(profile.oid);
        }
      }

      if (!profile.tags) return;
      res = this.notEmptyTags.find(tag => {
        return profile.tags.find(t => {
          return t === tag.oid;
        });
      });
      if (res) {
        set.add(profile.oid);
      }
    });

    this.edges.forEach(profile => {
      const res = this.notEmptyTags.find(tag => {
        return profile.tags.find(t => {
          return t === tag.oid;
        });
      });
      if (res) {
        set.add(profile.oid);
      }
    });

    const promises = [];
    const setOfLoadedRelations = new Set();
    this.sortedRelationSearches.forEach(relation => {
      relation.targets.forEach(target => {
        if (set.has(target)) {
          if (setOfLoadedRelations.has(target) && relation.title.indexOf('connections') === -1) return;
          setOfLoadedRelations.add(target);
          promises.push(this.$store.dispatch('loadConnections', relation));
        }
      });
    });
    Promise.all(promises).then(() => {
      this.showElementsOfReport = true;
    });
  },
  computed: {
    ...mapGetters(['currentCase', 'notEmptyTags', 'getReportItems', 'results', 'connections', 'getTags', 'getTitle', 'getSubtitle', 'getDescription', 'sortedConnections', 'multiprofiles', 'sortedRelationSearches', 'resultsMap', 'edges', 'reportId']),
    checkItemsToPreview () {
      let count = 0;
      if (Object.keys(this.taggedEntities).length) {
        for (const item in this.taggedEntities) {
          if (this.taggedEntities[item].checked) {
            count++;
          }
        }
      }
      this.multiprofiles.forEach(mp => {
        if (mp.checked) {
          count++;
        }
      });
      return count;
    },
    checkedScreenshots () {
      return this.$store.state.report.checkedScreenshots;
    },
    taggedEntities () {
      const obj = {};
      this.results.forEach(profile => {
        let res;
        if (this.multiprofiles?.length) {
          res = this.multiprofiles.find(mp => {
            return mp.entity_ids.find(id => {
              return id === profile.oid;
            });
          });
          if (res) {
            obj[profile.oid] = profile;
          }
        }

        if (!profile.tags) return;
        res = this.notEmptyTags.find(tag => {
          return profile.tags.find(t => {
            return t === tag.oid;
          });
        });
        if (res) {
          obj[profile.oid] = profile;
        }
      });

      this.edges.forEach(profile => {
        if (!profile.tags) return;
        const res = this.notEmptyTags.find(tag => {
          return profile.tags.find(t => {
            return t === tag.oid;
          });
        });
        if (res) {
          obj[profile.oid] = profile;
        }
      });

      this.connections.forEach(profile => {
        if (!profile.tags) return;
        const res = this.notEmptyTags.find(tag => {
          return profile.tags.find(t => {
            return t === tag.oid;
          });
        });
        if (res) {
          obj[profile.oid] = profile;
        }
      });
      return obj;
    },
    profilesForXLS () {
      const arr = [];
      const allTagProfiles = [];
      this.tagsInPreview.forEach(tag => {
        allTagProfiles.push(...this.getItemFromTag(tag));
      });
      if (allTagProfiles.length) {
        allTagProfiles.forEach(profile => arr.push([profile]));
      }
      this.multiprofilesInPreview.forEach(mp => {
        arr.push(this.getProfiles(mp));
      });
      return arr;
    }
  },
  methods: {
    showInputH1 () {
      this.inputH1 = true;
    },
    closeInputH1 () {
      this.inputH1 = false;
      this.title = this.getTitle;
    },
    showInputH2 () {
      this.inputH2 = true;
    },
    closeInputH2 () {
      this.inputH2 = false;
      this.subtitle = this.getSubtitle;
    },
    async updateReportHeaders (inputType = null) {
      try {
        const payload = {
          method: 'PUT',
          url: `/api/user/reports/${this.currentCase.oid}`,
          body: {
            title: this.title,
            subtitle: this.subtitle,
            description: this.description,
            items: this.getReportItems
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('setTitle', this.title);
        this.$store.commit('setSubtitle', this.subtitle);
        this.$store.commit('setDescription', this.description);
        if (inputType === 'h1') {
          this.inputH1 = false;
        } else if (inputType === 'h2') {
          this.inputH2 = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    openPreview () {
      this.loading = true;
      this.showPreview = true;
      this.previewItems();
      this.writeNotesFromReport();
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    previewItems () {
      const set = new Set();
      if (Object.keys(this.taggedEntities).length) {
        for (const item in this.taggedEntities) {
          if (this.taggedEntities[item].checked && this.taggedEntities[item].tags.length) {
            if (this.taggedEntities[item].level === 1 || this.taggedEntities[item].level === 3) {
              this.checkedResultsInTags.push(this.taggedEntities[item]);
              this.taggedEntities[item].tags.forEach(tag => {
                set.add(tag);
              });
            } else if (this.taggedEntities[item].level === 2) {
              this.checkedConnectionsInTags.push(this.taggedEntities[item]);
              this.taggedEntities[item].tags.forEach(tag => {
                set.add(tag);
              });
            }
          }
        }
        set.forEach(id => {
          const tag = this.notEmptyTags.find(tag => {
            return id === tag.oid;
          });
          if (tag) {
            this.tagsInPreview.push(tag);
          }
        });
        this.multiprofilesInPreview = [];
        this.multiprofiles.forEach(mp => {
          if (mp.checked) {
            this.multiprofilesInPreview.push(mp);
          }
        });
      }
    },
    closePreview () {
      this.showPreview = false;
      this.tagsInPreview = [];
      this.checkedResultsInTags = [];
      this.checkedConnectionsInTags = [];
    },
    writeNotesFromReport () {
      if (this.getReportItems && this.getReportItems.length) {
        this.getReportItems.forEach(item => {
          if (item.type === 1) {
            const result = this.results.find(res => {
              return res.oid === item.oid;
            });
            if (result) {
              result.note = item.note;
            }
          } else if (item.type === 2) {
            const result = this.connections.find(res => {
              return res.oid === item.oid;
            });
            if (result) {
              result.note = item.note;
            }
          } else if (item.type === 3) {
            const result = this.getTags.find(res => {
              return res.oid === item.oid;
            });
            if (result) {
              result.note = item.note;
            }
          } else {
            const result = this.multiprofiles.find(res => {
              return res.id === item.oid;
            });
            if (result) {
              result.note = item.note;
            }
          }
        });
      }
    },
    getItemFromTag (tag) {
      const arr = [];
      this.checkedResultsInTags.forEach(result => {
        if (result.tags.includes(tag.oid)) {
          arr.push(result);
        }
      });
      return arr;
    },
    getConnectionsFromTag (tag) {
      const arr = [];
      this.checkedConnectionsInTags.forEach(connection => {
        if (connection.tags.includes(tag.oid)) {
          arr.push(connection);
        }
      });
      return arr;
    },
    getSortedConnections (profile) {
      if (!profile.scoreToShow) return {};
      const connections = this.sortedConnections.filter(conn => {
        return conn.parent.oid === profile.oid;
      });

      const obj = {};
      obj['250+'] = [];
      obj['150-249'] = [];
      obj['50-149'] = [];
      obj['1-49'] = [];
      connections.forEach(connection => {
        if (profile.scoreToShow['s-250+'] && connection.fields.score.value >= 250) {
          obj['250+'].push(connection);
        } else if (profile.scoreToShow['s-150+'] && connection.fields.score.value >= 150 && connection.fields.score.value <= 249) {
          obj['150-249'].push(connection);
        } else if (profile.scoreToShow['s-50+'] && connection.fields.score.value >= 50 && connection.fields.score.value <= 149) {
          obj['50-149'].push(connection);
        } else if (profile.scoreToShow['s-1+'] && connection.fields.score.value <= 49) {
          obj['1-49'].push(connection);
        }
      });
      return obj;
    },
    sourceAvatar (profile) {
      return getEntityAvatar(profile);
    },
    url (profile) {
      return getEntityUrl(profile);
    },
    socialNetworkIcon (socialNetwork) {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (socialNetwork.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    },
    name (profile) {
      return getEntityName(profile);
    },
    connectionTypes (profile) {
      return profile.fields.connection_types.value.split(',').join(', ');
    },
    getProfiles (multiprofile) {
      const profiles = [];
      multiprofile.entity_ids.forEach(id => {
        for (const key of Object.keys(this.resultsMap)) {
          if (key === id) {
            profiles.push(this.resultsMap[key]);
          }
        }
      });
      return profiles;
    },
    createXLS () {
      // The profilesForXLS array is grouped into blocks for a multiprofile. For tags, each profile is in a separate block
      let ws, wb;
      this.profilesForXLS.forEach(block => {
        const userDetails = this.getUserDetails(block);
        const sortingUserDetails = this.getSortingUserDetails(userDetails);

        const info = this.getInfoOfProfiles(block);

        for (const key of Object.keys(sortingUserDetails)) {
          const headerBlock = sortingUserDetails[key];
          for (const keyHeader of Object.keys(headerBlock)) {
            let keyArr = keyHeader.split('<br>');
            if (keyArr.length > 1) {
              keyArr = keyArr[keyArr.length - 1];
            } else {
              keyArr = keyHeader;
            }
            const lowerCaseKey = keyArr.toLowerCase() + ` (${key})`;
            let str;
            if (Array.isArray(headerBlock[keyHeader].value)) {
              str = headerBlock[keyHeader].value.join(', ');
            } else {
              str = headerBlock[keyHeader].value;
            }
            info[lowerCaseKey] = {};
            info[lowerCaseKey].value = str;
            info[lowerCaseKey].socialNetwork = headerBlock[keyHeader].socialNetwork;
          }
        }

        const arrToXLS = [];
        for (const key of Object.keys(info)) {
          info[key].value = clearingString(info[key].value);
          const obj = {};
          obj.name = key;
          if (Array.isArray(info[key].value)) {
            obj.value = info[key].value.join(', ');
          } else {
            obj.value = info[key].value;
          }
          const socialNetworks = info[key].socialNetwork.reduce((str, social) => {
            if (social.indexOf('facebook') !== -1) {
              return str + 'facebook ';
            } else if (social.indexOf('Twitter') !== -1) {
              return str + 'twitter ';
            } else if (social.indexOf('instagram') !== -1) {
              return str + 'instagram ';
            } else {
              return str + 'linkedin ';
            }
          }, '');
          obj.socialNetwork = socialNetworks;
          arrToXLS.push(obj);
        }
        if (!ws) {
          ws = XLSX.utils.json_to_sheet(arrToXLS);
          wb = XLSX.utils.book_new();
        } else {
          ws = XLSX.utils.sheet_add_json(ws, arrToXLS, { skipHeader: true, origin: -1 });
        }
      });
      XLSX.utils.book_append_sheet(wb, ws, 'People');
      XLSX.writeFile(wb, `${this.title || 'Report'}.xlsx`);
    },
    sendHtml () {
      document.querySelectorAll('.d-none').forEach(elem => {
        elem.remove();
      });
      const el = document.querySelector('#htmltopdf');

      const domClone = el.cloneNode(true);
      domClone.querySelectorAll('.csv-btn').forEach(elem => {
        elem.remove();
      });
      const svgImages = domClone.querySelectorAll('[data-svg-replace]');
      svgImages.forEach(elem => {
        const img = document.createElement('img');
        img.src = `${document.location.origin}/icons/${elem.dataset.svgReplace}.svg`;
        domClone.querySelector(`[data-svg-replace=${elem.dataset.svgReplace}]`).replaceWith(img);
      });

      const htmlStr = domClone.innerHTML;

      let allHtml = '<div class="report-preview"><div class="report-preview_wrap">';
      allHtml += htmlStr;
      allHtml += allHtml + '</div></div>';
      const allClasses = [];

      const allElems = domClone.querySelectorAll('*');
      allElems.forEach(a => {
        if (a.classList.length) {
          a.classList.forEach(c => {
            if (!allClasses.includes(`.${c}`)) allClasses.push(`.${c}`);
          });
        }
      });
      const caseId = this.currentCase.oid;
      const reportId = this.reportId;
      const store = this.$store;
      getCssData({
        async onComplete (cssText) {
          cssText = cssText.replace(/(\r\n|\n|\r)/gm, ' ');
          const finalHtml = `${allHtml}<style type="text/css">${cssText}</style>`;

          try {
            const formdata = new FormData();
            const data = {
              case_id: caseId,
              report_id: reportId
            };
            formdata.append('data', JSON.stringify(data));
            formdata.append('html', finalHtml);
            const payload = {
              method: 'POST',
              url: '/api/report/snapshot',
              body: formdata
            };
            const result = await store.dispatch('ajaxWithTokenRefresh', payload);
            window.open(result.data.url);
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .case-name-input {
    border: 0;
    outline: none;
    border-bottom: 2px solid #BFD2DF;
    color: #BFD2DF;
    background: none !important;
  }
  .h-48 {
    height: 48px;
  }
  .loading-block {
    padding: 35vh 30vw 0 0;
  }
  .opacity-btn {
    &::before {
      content: "";
      border-top: 2px solid #60BCE3;
      border-left: 2px solid #60BCE3;
      width: 10px;
      height: 10px;
      transform: rotate(-45deg);
    }
  }
  .empty-report__image {
    padding: 75px 120px;
    background: #F8FCFF;
  }
  .empty-report__content {
    padding: 49px 120px;
    width: 100%;
    background: #fff;
    h3 {
      margin-bottom: 12px;
      letter-spacing: -0.02em;
    }
    p {
      letter-spacing: 0.04em;
    }
  }
  .flex-grow {
    padding: 4px 8px;
    position: relative;
    img {
      width: 100%;
      padding: 5px 0;
    }
  }
  .w50 {
    width: 50%;
  }
  .custom-color {
    color: #29292B;
  }
</style>
