<template>
  <ReportComponent />
</template>

<script>
import ReportComponent from '../components/report/ReportComponent';

export default {
  name: 'Report',
  components: {
    ReportComponent
  }
};
</script>
