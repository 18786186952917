<template>
  <div class="report-item mb-4">
    <div class="tagreport-block" :class="showSublist ? 'tagreport-block--open' : ''">
      <div class="d-flex align-center">
        <span class="tag-mp-icon mr-8" :style="{color:tag.color, border:`2px solid ${tag.color + 30}`}">tag</span>
        <div class="d-flex">
          <button class="report-open-sublist color-1 fw-600 mr-10" @click="openSublist(tag.oid)">
            <span class="tagname mr-12">{{tag.name}}</span>
            <span class="tagcounter mr-12">({{countOfEntities}} entities)</span>
            <Icon name="arrow-down-white" :class="showSublist ? 'arrow-up-white' : 'arrow-down-white'" class="mr-12" color="#60BCE3" />
          </button>
          <span class="typography-7 w-100">{{infoAboutTag}}</span>
        </div>
      </div>
      <div class="d-flex">
        <input type="checkbox" name="" class="sidebar-checkbox" :checked="tag.checked">
        <label class="sidebar-checkbox__label" @click="checkAllProfiles(tag)"></label>
      </div>
    </div>

    <div :ref="'sublist-' + tag.oid" :id="'sublist-' + tag.oid" class="sublist-accordeon">
      <div class="note-block pb-0">
        <label for="" class="mb-12 color-miniheader typography-6 fw-600">Note</label>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          class="note-area w-100"
          placeholder="Place for your note"
          v-model="tagNote"
          @blur="saveNote(tag)"
        ></textarea>
      </div>
      <ul class="note-block">
        <div class="color-miniheader typography-6 mb-12 fw-600">Saved in tag</div>
        <template v-for="entity in tagEntities" :key="entity.oid">
          <ReportItem :entity="entity" :tag="tag"/>
        </template>
      </ul>
    </div>
  </div>
  <!-- <ul :ref="'sublist-' + tag.oid" :id="'sublist-' + tag.oid" class="sublist-accordeon">
    <div class="screen-container__title d-flex justify-between" v-if="screenUrl.length">
      <span>Map screenshots</span>
      <button  class="d-flex align-center mr-32 toggle-screenshots" @click="toggleShowScreens">
        <span v-if="showScreens" class="toggle-btn">Hide</span>
        <span v-if="!showScreens" class="toggle-btn">Show</span>
      </button>
    </div>
    <div class="screen-container d-flex flex-wrap w-100" v-show="showScreens">
      <div class="d-flex flex-column flex-grow px-8"
      :class="{w50: screenUrl.length > 1}"
      v-for="(screen,index) of screenUrl"
      :key="index">
        <img class="flex-img" :src="screen.url" alt="">
        <div class="d-flex checkbox-container">
          <input type="checkbox" name=""
          class="sidebar-checkbox select-screenshot"
          :value="screen"
          :checked="screen.checked">
          <label class="sidebar-checkbox__label" @click="selectScreen(screen)"></label>
        </div>
      <div class="container-in d-flex justify-between align-center pb-12" v-if="screen">
        <span class="screen-name">ScreenShot {{screenShotTime}}</span>
        <a class="d-flex align-center pointer delete-screenshot"
        @click="removeScreen(screen)"
        @mouseover="delHover = screen"
        @mouseleave="delHover = null">
          <span>DELETE</span>
          <Icon class="ml-10" name="trash"/>
        </a>
      </div>
      </div>
    </div>
    <template v-for="entity in tagEntities" :key="entity.oid">
      <ReportItem :entity="entity" :tag="tag" @change="change"/>
    </template>
  </ul> -->
</template>

<script>
import Icon from '../app/Icon.vue';
import ReportItem from './ReportItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ReportTag',
  components: {
    Icon,
    ReportItem
  },
  data () {
    return {
      tagNote: '',
      checkedTags: false,
      showSublist: false,
      checkedScreen: [],
      checkedScreenIndex: [],
      selectedScreen: [],
      delHover: null,
      showScreens: false
    };
  },
  props: ['tag'],
  emits: ['addTaggedEntities'],
  created () {
    this.getReportItems.forEach(item => {
      if (item.oid === this.tag.oid) {
        this.tagNote = item.note || '';
      }
    });
  },
  computed: {
    ...mapGetters(['results', 'getReportItems', 'sortedRelationSearches']),
    screenShotTime () {
      return new Date(this.screen.time * 1000).toLocaleString();
    },
    screenUrl () {
      const foundScreenshot = this.$store.state.report.mapScreens.flat().filter(item => item.tag_id === this.tag.oid);
      return foundScreenshot || '';
    },
    tagEntities () {
      const entities = [];
      const entitiesMap = {};
      const addTaggedEntities = (entities) => {
        Object.keys(entities).forEach(oid => {
          const ent = entities[oid];
          if (ent.tags && ent.tags.length) {
            if (ent.tags.includes(this.tag.oid)) {
              entitiesMap[ent.oid] = ent;
            }
          }
        });
      };
      const resultsMap = this.$store.getters.resultsMap;
      addTaggedEntities(resultsMap);
      Object.keys(resultsMap).forEach(oid => { addTaggedEntities(resultsMap[oid].children || []); });
      addTaggedEntities(this.$store.getters.connectionEntitiesMap);
      addTaggedEntities(this.$store.getters.edgesMap);
      addTaggedEntities(this.$store.getters.mergedConnectionsBetween);

      Object.keys(entitiesMap).forEach(oid => entities.push(entitiesMap[oid]));
      // this.$emit('addTaggedEntities', entitiesMap);

      if (this.getReportItems.length) {
        this.getReportItems.forEach(item => {
          const el = entities.find(ent => {
            return item.oid === ent.oid;
          });
          if (el) {
            el.note = item.note;
          }
        });
      }

      return entities;
    },
    infoAboutTag () {
      const entities = this.tagEntities;
      let countOfProfiles = 0;
      let countOfConnections = 0;
      if (entities && entities.length) {
        entities.forEach(entity => {
          if (!entity.typeid) {
            countOfConnections++;
          } else {
            countOfProfiles++;
          }
        });
      }
      let str1, str2;
      if (countOfProfiles > 1) {
        str1 = countOfProfiles + ' profiles ';
      } else if (countOfProfiles === 1) {
        str1 = countOfProfiles + ' profile ';
      } else {
        str1 = '';
      }
      if (countOfConnections > 1) {
        str2 = countOfConnections + ' connections';
      } else if (countOfConnections === 1) {
        str2 = countOfConnections + ' connection';
      } else {
        str2 = '';
      }
      return str1 + str2;
    },
    countOfEntities () {
      let countOfProfiles = 0;
      let countOfConnections = 0;
      if (this.tagEntities && this.tagEntities.length) {
        this.tagEntities.forEach(entity => {
          if (entity.src) {
            countOfConnections++;
          } else {
            countOfProfiles++;
          }
        });
      }
      return countOfProfiles + countOfConnections;
    },
    showScoreFilterBlock () {
      let res;
      for (let i = 0; i < this.sortedRelationSearches.length; i++) {
        const targets = this.sortedRelationSearches[i].targets;
        for (let j = 0; j < targets.length; j++) {
          res = this.tagEntities.find(entity => {
            return targets[j] === entity.oid;
          });
          if (res) break;
        }
        if (res) break;
      }
      return res;
    }
  },
  methods: {
    toggleShowScreens () {
      this.showScreens = !this.showScreens;
    },
    selectScreen (item) {
      this.$store.commit('checkScreenshots', item);
      if (item.checked) {
        this.checkedScreen.push(item);
      } else {
        this.checkedScreen.splice(item, 1);
      }
    },
    removeScreen (item) {
      const payload = {
        method: 'DELETE',
        url: '/api/user/reports/screenshots',
        body: {
          id: item.id,
          case_id: this.$store.state.results.case.oid
        }
      };
      this.$store.dispatch('ajaxWithTokenRefresh', payload);
      this.$store.commit('removeReportMapScreens', item);
    },
    change (value) {
      this.checkedTags = value;
    },
    openSublist (oid) {
      const elem = this.$refs['sublist-' + oid];
      if (elem.style.maxHeight) {
        elem.style.maxHeight = null;
        this.showSublist = false;
      } else {
        elem.style.maxHeight = elem.scrollHeight + 'px';
        this.showSublist = true;
      }
    },
    checkAllProfiles (tag) {
      tag.checked ? tag.checked = false : tag.checked = true;
      const profiles = this.tagEntities;
      if (profiles && profiles.length) {
        profiles.forEach(profile => {
          tag.checked ? profile.checked = true : profile.checked = false;
        });
      }
      this.results.forEach(profile => {
        if (profile.tags && profile.tags.length) {
          if (profile.tags.includes(tag.oid)) {
            tag.checked ? profile.checked = true : profile.checked = false;
          }
        }
      });
    },
    async saveNote (tag) {
      try {
        const obj = {
          entity: tag,
          caseId: this.$route.params.id,
          note: this.tagNote
        };
        await this.$store.dispatch('saveItemNote', obj);
      } catch (error) {
        console.error(error);
      }
    },
    checkConnections (score) {
      const elem = this.$refs[score];
      if (elem.checked) {
        elem.checked = false;
        this.sortedConnByScore[score] = false;
      } else {
        elem.checked = true;
        this.sortedConnByScore[score] = true;
      }
      this.$store.commit('setScoreForPreview', { tag: this.tag.oid, score: this.sortedConnByScore });
    }
  }
};
</script>

<style lang="scss">
  .sublist-accordeon {
    background-color: #f8fcff;
  }
  .w50 {
    width: 50%;
  }
  .flex-grow {
    position: relative;
    img {
      width: 100%;
    }
  }
  .screen-container {
    font-family: "Segoe UI", "Segoe UI Mac", Avenir, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #8D8D8E;
    padding: 0 24px;
  }
  .checkbox-container {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .container-in {
    padding-top: 5px;
  }
  .delete-screenshot {
    &:hover {
      color: #60BCE3;
      svg {
        path {
          fill: #60BCE3;
        }
      }
    }
  }
  .screen-container__title {
    font-family: "Segoe UI", "Segoe UI Mac", Avenir, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    color: #29292B !important;
    padding: 24px 0 12px 32px;
  }
</style>
